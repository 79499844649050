<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:submit_preprocessing="submit_preprocessing" :slot_table_list="['operation','status']" ref="list" @get_select_value="get_select_value">
			<template slot="right_btn">
				<a :href="('/manage/crm.trip/exportTripPlace&status=' + status) | export_form" target="_blank">
					<a-button class="a_btn">导出</a-button>
				</a>
			</template>
			<template slot="left_btn">
				<div class="left_btn_box">
					<div class="recharge_all">
						<p v-for="(item,index) in status_screen_list" :key="item.value"
							@click="status_screen(item,index)">{{item.label}}<span
								:class="'all_money ' + (index == screen_index && 'active')">{{dataInfo[item.type]}}</span></p>
					</div>
				</div>
			</template>
			<template slot="operation" slot-scope="data">
				<span>
					<a @click="handle_details_click(data.record)">查看</a>
				</span>
			</template>
			<template slot="depart_province" slot-scope="data">
				<span>
					{{data.record.depart_province+data.record.depart_city+data.record.depart_address}}
				</span>
			</template>
			<template slot="destination_province" slot-scope="data">
				<span>
					{{data.record.destination_province+data.record.destination_city+data.record.destination_city}}
				</span>
			</template>
			<template slot="status" slot-scope="data">

				<span v-if="data.record.status=='-2'">
					已撤回
				</span>
				<span v-else-if="data.record.status=='-1'">
					拒绝审批退回
				</span>
				<span v-else-if="data.record.status=='0'">
					待审批
				</span>
				<span v-else-if="data.record.status=='1'">
					审批中
				</span>
				<span v-else-if="data.record.status=='2'">
					已同意
				</span>
			</template>
		</TableList>
		<Details ref="details"></Details>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Details from "./approval/approval_details.vue";
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	// List
	import TableList from "@/components/TableList";
	import {
		getTripApproval,
		countApproval,
		getApprovalDetail,
		exportTripPlace,
		getApprovalTypeSelect
	} from "@/api/evection.js";

	const columns = [{
			title: "审批编号",
			dataIndex: "approval_no"
		},
		{
			title: "申请类型",
			dataIndex: "type_name"
		},
		{
			title: "申请人",
			dataIndex: "username",
			scopedSlots: {
				customRender: "username"
			}
		},
		{
			title: "申请人部门",
			dataIndex: "department_name"
		},
		{
			title: "审批提交时间",
			dataIndex: "create_time",

		},
		{
			title: "状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			}
		},
		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];

	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			Details
		},
		data() {
			return {
				status_list:[
					...this.$config.status_all,
					...this.$config.approval_status_list
				],
				screen_index: 0,
				status_screen_list: [{
						label: '审批数量',
						type: 'total',
						value: ''
					},
					{
						label: '未审批',
						type: 'none',
						value: 0
					},
					{
						label: '进行中审批',
						type: 'in_progress',
						value: 1
					},
				],
				config: this.$config,
				get_table_list: getTripApproval,
				submit_preprocessing: {
					array_to_string: ['department_id', 'group_id']
				},
				columns,
				selectedRowKeys: [],
				loading: false,
				visible: false,
				transfer_type: 'jump_customer',
				transfer_name: 'uid',
				transfer_list: {
					uid: '',
					to_uid: ''
				},
				transfer_visible: false,
				user_visible: false,
				form_data_seo: {
					list: [{
							type: "text",
							name: "keyword",
							title: "申请人",
							placeholder: "申请人姓名",
							options: {}
						},
						{
							type: "tree-select",
							name: "department_id",
							title: "申请人部门",
							options: {},
							treeData: [],
							multiple: true
						},

						{
							type: "select",
							name: "type",
							title: "申请类型",
							mode: "default",
							options: {},
							list: []
						},
						{
							type: "range_date",
							name: "range_date",
							title: "审批提交时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},
						{
							type:"select",
							name:"status",
							title:'审批状态',
							list: [
								{
									key: 0,
									value: '待审批'
								},
								{
									key: 1,
									value: '审批中'
								},
								{
									key: 2,
									value: '已同意'
								},
								{
									key: -1,
									value: '已拒绝'
								},
								{
									key: -2,
									value: '已撤回'
								},
							]
						}
					],
					...this.$config.form_data_seo
				},
				customer_ids: '',
				select_user: [],
				dataInfo: {},
				status:'',// 审批状态的值（每次选择时都会发生改变
			};
		},

		async created() {
			Promise.all([this.$method.get_department(), this.$method.get_group(), this.$method.get_level()]).then(
				res => {
					this.form_data_seo.list.forEach(item => {
						if (item.name == "department_id") {
							item.treeData = res[0];
						}

					});
				});
			this.get_info()

		},
		mounted() {
			if (this.$route.query.id) {
				this.handle_details_click({
					id: this.$route.query.id,
					approval_type_id: this.$route.query.approval_type_id
				})
			}
			this.get_ApprovalTypeSelect()
		},
		methods: {
			get_select_value(e){
				this.status = e;
			},
			status_screen(item, index) {
				this.screen_index = index;
				this.status = this.status_screen_list[this.screen_index].value
				this.$refs.list.fixed_seo_data.status = this.status_screen_list[this.screen_index].value;
				this.$refs.list.get_list();
			},
			get_ApprovalTypeSelect() {
				getApprovalTypeSelect().then(res => {

					res.data.traffic = res.data.list.map(item => {
						item.key = item.value;
						item.value = item.label;
						return item;
					});
					this.form_data_seo.list.forEach(item => {
						if (item.name == "type") {
							item.list = res.data.list;
						}
					});
				})
			},
			handle_details_click(record) {
				this.$refs.details.get_details(record);
			},
			get_info() {
				countApproval().then(res => {
					this.dataInfo = res.data.data
				})
			},
			async get_list(data = {}) {
				try {
					await getTripApproval({
						_this: this,
						data
					}).then(res => {
						let list = res.res.data.list.data;
						this.list = list
					});
				} catch (e) {}
			},
			add() {
				this.$router.push("/customer/index_edit");
			},
			edit(key) {
				this.$router.push("/customer/index_edit?id=" + key.id);
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.recharge_all {
		font-size: 16px;
		display: flex;
		color: #333;

		.all_money {
			font-size: 20px;
			color: #FF0000;
			margin-left: 10px;
			margin-right: 5px;
			display: inline-block;
			cursor: pointer;
		}
		.all_money.active{
			color: #0079FF;
		}

		p:nth-child(2),
		p:nth-child(3) {
			margin-left: 40px;
		}
	}
</style>
